<template>
    <div ref="modal" class="modal fade" id="modal_delete" tabindex="-1" aria-labelledby="modal_deleteLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-delete-content">
                <div class="modal-body">
                    <div class="head-mod-container">
                        <div class="msg-icon">
                            <svg id="info-icon" xmlns="http://www.w3.org/2000/svg" width="114" height="114" viewBox="0 0 114 114">
                                <path id="Path_79" data-name="Path 79" d="M56.846,0C88.358,0,114,25.642,114,56.846A57.223,57.223,0,0,1,56.846,114C25.642,114,0,88.358,0,56.846A57.093,57.093,0,0,1,56.846,0Zm2.472,21.935A9.3,9.3,0,0,1,68.585,31.2a9.5,9.5,0,0,1-9.268,9.268,9.268,9.268,0,0,1,0-18.537ZM72.6,84.341c-2.78,10.2-29.967,11.122-28.114-2.78,1.236-9.268,3.089-18.228,4.634-27.187.927-5.87-2.78-4.016-6.179-5.252-2.163-.927-2.163-3.707.618-4.325,3.4-.927,15.447-.309,19.463-.309,2.163,0,3.4,1.236,3.707,3.4a30.437,30.437,0,0,1-.618,5.252c-1.545,8.65-3.707,17.919-4.943,26.569,0,1.854.309,5.252,3.089,4.943,2.78,0,4.016-1.236,6.179-2.163C71.675,81.87,72.911,82.8,72.6,84.341Z" fill="#6c782a" fill-rule="evenodd"/>
                            </svg>
                        </div>
                        <h3 class="head-modal-title">
                            Notice
                        </h3>
                        <p class="des-modal">
                            You are deleting <span>John Doe</span> from Church Member list. Proceed?
                        </p>
                    </div>
                    <div class="d-grid gap-2">
                        <button type="button" class="btn btn-submit-delete" @click="confirmDelete">Confirm</button>
                        <button type="button" class="btn btn-cancel-modal" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapMutations } from 'vuex';

export default defineComponent({
    props: {
        targetUser: {
            type: Number,
            default: null
        }
    },
    methods: {
        async confirmDelete() {
            window['$'](this.$refs['modal']).modal('hide');
            await this.removeUser(this.targetUser);
            this.removeUserCache(this.targetUser);
        },
        ...mapActions('user-church', { removeUser: 'remove' }),
        ...mapMutations('vw-users-church', { removeUserCache: 'removeTemps' })
    }
})
</script>
