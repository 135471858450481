<template>
    <section class="church-members-section">
        <div class="container my-container">
            <div class="church-members-content">
                <div class="row no-gutters">
                    <div class="col-sm-6">
                        <h4>Church Members</h4>
                    </div>
                    <div class="col-md-6">
                        <a href="#" class="nav-link" @click.prevent="$router.push('/confirm-users')">Confirm New Request</a>
                    </div>
                    <div class="col-md-3">
                        <form class="d-flex" @submit.prevent="">
                            <input v-model="search" class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                        </form>
                    </div>
                    <div class="col-md-6"></div>
                    <div class="col-md-3">
                        <div class="mb-3 row">
                            <label for="staticEmail" class="col-sm-2 col-form-label">Sort</label>
                            <div class="col-sm-10">
                                <select v-model="sort" class="form-select d-flex" aria-label="Default select example">
                                    <option :value="null">All</option>
                                    <option value="fname">First Name</option>
                                    <option value="lname">Last Name</option>
                                    <option value="email">Email</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-container">
                    <table class="table table-borderless table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">First Name</th>
                                <th scope="col">Last Name</th>
                                <th scope="col">Email</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item) in userChurch" :key="item.id">
                                <td scope="row">1</td>
                                <td>{{ item.fname }}</td>
                                <td>{{ item.lname }}</td>
                                <td>{{ item.email }}</td>
                                <td>
                                    <a href="#" data-bs-toggle="modal" data-bs-target="#modal_delete" @click.prevent="removeUser(item.id)">
                                        <i class="fas fa-trash-alt"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <nav aria-label="Page navigation" class="pagination-member">
                    <ul class="pagination">
                        <li class="page-item">
                            <a class="page-link" @click.prevent="paginationPrev" href="#" aria-label="Previous">
                                <i class="fas fa-chevron-left"></i>
                            </a>
                        </li>
                        <li class="page-item" v-for="index in pagination.totalPages" :key="index">
                            <a :class="['page-link', {'active': index === pagination.currentPage}]" href="#" @click.prevent="paginateTo(index)">{{ index }}</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" @click.prevent="paginationNext" href="#" aria-label="Next">
                                <i class="fas fa-chevron-right"></i>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </section>
    <RemoveUserConfirm :target-user="targetRemoveUser" />
</template>

<script>
import { useFind } from '@feathersjs/vuex';
import { computed, defineComponent, getCurrentInstance, ref } from 'vue';
import RemoveUserConfirm from '../components/modals.component/modal-remove-confirm.vue';

export default defineComponent({
    data() {
        return {
            targetRemoveUser: null
        };
    },
    setup() {
        const vm = getCurrentInstance();

        const { Church, VWUsersChurch }  = vm.appContext.provides.$FeathersVuex.api;
        const currentUser = vm.appContext.provides.store.state.auth.user;

        const page = ref(1);
        const search = ref(null);
        const sort = ref(null);

        const generatedQuery = computed(() => {
            const result = {};
            if(search.value) {
                result['$or'] = [
                    { fname: { $like: `%${search.value}%` } },
                    { lname: { $like: `%${search.value}%` } },
                    { email: { $like: `%${search.value}%` } }
                ];
            }
            return result;
        });

        const escapeRegex = (string) => {
            return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
        };

        const generatedQueryLocal = computed(() => {
            const result = {};
            if(search.value) {
                result['$or'] = [
                    { fname: { $regex: new RegExp(`.*${escapeRegex(search.value)}.*`, 'gi') } },
                    { lname: { $regex: new RegExp(`.*${escapeRegex(search.value)}.*`, 'gi') } },
                    { email: { $regex: new RegExp(`.*${escapeRegex(search.value)}.*`, 'gi') } },
                ];
            }
            if(sort.value) {
                result['$sort'] = [
                    { [sort.value]: -1 }
                ]
            }
            return result;
        });

        const church = useFind({ model: Church, params: computed(() => {
            return { query: { owner_id: currentUser.id } };
        }), fetchParams: computed(() => {
            return { query: { owner_id: currentUser.id } };
        }), immediate: true });

        const userChurch = useFind({
            model: VWUsersChurch,
            params: computed(() => {
                return { 
                    query: {
                        church_id: church.items.value.length !== 0 ? church.items.value[0].id : 0,
                        status: 'Confirmed',
                        $skip: (page.value - 1) * 10,
                        ...generatedQueryLocal.value
                    }
                };
            }),
            fetchParams: computed(() => {
                return { 
                    query: {
                        church_id: church.items.value.length !== 0 ? church.items.value[0].id : 0,
                        status: 'Confirmed',
                        $skip: (page.value - 1) * 10,
                        ...generatedQuery.value
                    }
                };
            })
        });

        return {
            userChurch: userChurch.items,
            userChurchPagination: userChurch.paginationData,
            page,
            search,
            sort
        };
    },
    computed: {
        pagination() {
            if(this.userChurchPagination.default) {
                const pagination = this.userChurchPagination.default.mostRecent;
                const itemsPerPage = pagination.pageParams.$limit;
                return {
                    totalPages: Math.ceil(pagination.total / itemsPerPage),
                    currentPage: Math.ceil((pagination.pageParams.$skip) / itemsPerPage) + 1
                };
            } else {
                return {
                    totalPages: 0,
                    currentPage: 0
                };
            }
        }
    },
    methods: {
        paginationNext() {
            if(this.pagination.currentPage !== this.pagination.totalPages) {
                this.paginateTo(this.pagination.currentPage + 1);
            }
        },
        paginationPrev() {
            if(this.pagination.currentPage !== 1) {
                this.paginateTo(this.pagination.currentPage - 1);
            }
        },
        paginateTo(index) {
            if(this.pagination.currentPage !== index) {
                this.page = index;
            }
        },
        removeUser(id) {
            this.targetRemoveUser = id;
        }
    },
    components: {
        RemoveUserConfirm
    }
})
</script>
